import React, { useEffect, useState, useContext} from 'react';
import { useParams, Link, Route, Routes, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axiosInstance from './api/axiosConfig';
import MyAds from './MyAds';
import Annuncio from './Annuncio';
import Onboarding from './Onboarding';
import CreateAd from './CreateAd';
import { AuthContext } from './App';
import DirectMessages from './DirectMessages';
import AdsFollow from './AdsFollow'; // Aggiungi questa riga per importare AdsFollow

const AreaRiservataContainer = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f8f8f8;
`;

const Sidebar = styled.nav`
  width: 250px;
  background-color: #800080;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MenuItem = styled(Link)`
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  margin: 10px 0;
  width: 100%;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #720072;
  }
`;

const LogoutButton = styled.button`
  background: none;
  border: none;
  padding: 10px 20px;
  color: white;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  text-align: center;

  &:hover {
    color: #ddd;
  }
`;

const MainContent = styled.div`
  flex-grow: 1;
  padding: 20px;
  box-sizing: border-box;
`;

const Title = styled.h1`
  color: #800080;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Info = styled.p`
  color: #333;
  font-size: 1.2rem;
  margin-bottom: 20px;
`;

const AreaRiservata = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [isOnboardingComplete, setIsOnboardingComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { handleLogout } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('Token non trovato. Effettua nuovamente il login.');
        setIsLoading(false);
        return;
      }

      try {
        const response = await axiosInstance.get(`/area_riservata/${userId}/`, {
          headers: {
            'Authorization': `Token ${token}`,
          },
        });
        setUser(response.data.user);
        setIsOnboardingComplete(response.data.isOnboardingComplete);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Errore nel caricamento dei dati utente. Riprova più tardi.');
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [userId]);

  const handleLogoutClick = async () => {
    try {
      await axiosInstance.post('/logout/');
      handleLogout();
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  useEffect(() => {
    console.log('Rendering AreaRiservata component');
    console.log('isOnboardingComplete:', isOnboardingComplete);
    console.log('User:', user);
  }, [isOnboardingComplete, user]);


  if (isLoading) {
    return <div>Caricamento in corso...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!user) {
    return <div>Nessun utente trovato. Effettua il login.</div>;
  }

  if (!isOnboardingComplete) {
    return <Onboarding />;
  }

  return (
    <AreaRiservataContainer>
      <Sidebar>
        <MenuItem to="profilo">Il mio profilo</MenuItem>
        <MenuItem to="inserisci-annuncio">Inserisci Annuncio</MenuItem>
        <MenuItem to="miei-annunci">I miei annunci</MenuItem>
        <MenuItem to="annunci-seguiti">Annunci Seguiti</MenuItem>
        <MenuItem to="messaggi">Direct Message</MenuItem>
        <MenuItem to="rewards">Bolly Rewards Program</MenuItem>
        <LogoutButton onClick={handleLogoutClick}>Logout</LogoutButton>
      </Sidebar>
      <MainContent>
        <Title>Area Riservata</Title>
        <Routes>
          <Route path="profilo" element={<Info>Il mio profilo</Info>} />
          <Route path="inserisci-annuncio" element={<CreateAd />} />
          <Route path="miei-annunci" element={<MyAds userId={userId} />} />
          <Route path="annunci-seguiti" element={<AdsFollow />} />
          <Route path="messaggi" element={<DirectMessages userId={userId} />} />
          <Route path="rewards" element={<Info>Bolly Rewards Program</Info>} />
          <Route path="annuncio/:id" element={<Annuncio fromAreaRiservata={true} />} />
          <Route index element={<Content>Benvenuto, {user.name}</Content>} />
        </Routes>
      </MainContent>
    </AreaRiservataContainer>
  );
};

export default AreaRiservata;
