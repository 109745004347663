import React from 'react';
import styled, { css } from 'styled-components';

const ChipContainer = styled.div`
  display: inline-block;
  padding: 16px 32px;
  border-radius: 24px;
  background-color: #F2F4F8;
  font-size: 16px;
  cursor: pointer;
  margin: 8px;

  &:hover {
    background-color: #A669F7;
    color: white;
  }

  ${props => props.active === 'true' && css`
    background-color: #A669F7;
    color: white;
  `}
`;

const Chip = ({ label, active, onClick }) => {
  return (
    <ChipContainer active={active.toString()} onClick={onClick}>
      {label}
    </ChipContainer>
  );
};

export default Chip;
