import React from 'react';
import styled from 'styled-components';

const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  margin-bottom: 20px;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
`;

const Button = styled.button`
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #333;
  }
`;

const Slide3 = ({ formData, handleChange, nextStep, prevStep }) => {
    return (
        <SlideContainer>
            <Title>Perchè ti sei iscritto a Perstudenti.com?</Title>
            <Select
                name="motivo_iscrizione"
                value={formData.motivo_iscrizione}
                onChange={handleChange('motivo_iscrizione')}
                required
            >
                <option value="">Seleziona...</option>
                <option value="vendere_appunti">Voglio vendere appunti/libri</option>
                <option value="vendere_oggetti">Voglio vendere degli oggetti che ho a casa</option>
                <option value="acquistare_libro">Devo acquistare un libro</option>
                <option value="comprare_bicicletta">Voglio comprare una bicicletta o un oggetto per la casa</option>
                <option value="altro">Altro</option>
            </Select>
            <Button onClick={prevStep}>Indietro</Button>
            <Button onClick={nextStep}>Avanti</Button>
        </SlideContainer>
    );
};

export default Slide3;
