import React, { useEffect, useState } from 'react';
import axiosInstance from './api/axiosConfig';  // Usa l'istanza Axios configurata
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const Annuncio = styled.div`
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

const AnnuncioImage = styled.img`
  max-width: 100px;
  margin-right: 10px;
`;

const AnnuncioDetails = styled.div`
  flex: 1;
`;

const AnnuncioTitle = styled.h2`
  margin: 0 0 10px;
`;

const AnnuncioInfo = styled.p`
  margin: 5px 0;
`;

const MyAds = ({ userId }) => {
  const [ads, setAds] = useState([]);

  useEffect(() => {
    axiosInstance.get(`/area_riservata/${userId}/i_miei_annunci`)
      .then(response => {
        setAds(response.data);
      })
      .catch(error => {
        console.error('Error fetching ads:', error);
      });
  }, [userId]);

  return (
    <Container>
      <h1>I Miei Annunci</h1>
      {ads.length > 0 ? (
        ads.map(annuncio => (
          <Annuncio key={annuncio.id}>
            <a href={`/annuncio/${annuncio.id}`}>
              <AnnuncioImage src={annuncio.img_main_url} alt="Immagine annuncio" />
            </a>
            <AnnuncioDetails>
              <AnnuncioTitle>{annuncio.titolo}</AnnuncioTitle>
              <AnnuncioInfo>Data di pubblicazione: {annuncio.data_pubblicazione}</AnnuncioInfo>
              <AnnuncioInfo>Views: {annuncio.views}</AnnuncioInfo>
              <AnnuncioInfo>Stato: {annuncio.stato}</AnnuncioInfo>
              {annuncio.data_cessazione && <AnnuncioInfo>Data di Cessazione: {annuncio.data_cessazione}</AnnuncioInfo>}
            </AnnuncioDetails>
          </Annuncio>
        ))
      ) : (
        <p>Non hai ancora pubblicato alcun annuncio.</p>
      )}
    </Container>
  );
};

export default MyAds;
