// src/api/axiosConfig.js
import axios from 'axios';
import { csrfToken } from '../utils/csrf';

// Usa la variabile di ambiente REACT_APP_API_URL per l'URL base
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http://localhost:8000/api', // Fallback a localhost se la variabile non è definita
    headers: {
        'X-CSRFToken': csrfToken,
        'Content-Type': 'application/json',
    },
    withCredentials: true, // Assicurati di includere i cookie nelle richieste
});

export default axiosInstance;
