import React from 'react';
import styled from 'styled-components';

const ReviewsContainer = styled.section`
  padding: 20px;
  background-color: #f8f8f8;
  text-align: center;
`;

const SectionTitle = styled.h2`
  color: #333;
  margin-bottom: 20px;
`;

const ReviewsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const ReviewCard = styled.div`
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  width: 250px;
  text-align: center;
`;

const Reviews = () => (
    <ReviewsContainer>
        <SectionTitle>Recensioni</SectionTitle>
        <ReviewsGrid>
            {/* Replace with dynamic review components */}
            <ReviewCard>Recensione 1</ReviewCard>
            <ReviewCard>Recensione 2</ReviewCard>
            <ReviewCard>Recensione 3</ReviewCard>
            <ReviewCard>Recensione 4</ReviewCard>
        </ReviewsGrid>
    </ReviewsContainer>
);

export default Reviews;
