import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from './api/axiosConfig';  // Usa l'istanza Axios configurata


function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

const CompleteRegister = () => {
    const { uidb64, token } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const completeRegistration = async () => {
            const csrftoken = getCookie('csrftoken');
            try {
                const response = await axiosInstance.post(
                    `/api/complete_registration/${uidb64}/${token}/`,
                    {},
                    {
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest',
                            'X-CSRFToken': csrftoken,
                        },
                        withCredentials: true,
                    }
                );
                console.log('Response:', response.data);
                if (response.data.status === 'success') {
                    navigate(`/onboarding/${uidb64}/${token}`);
                } else {
                    console.error(response.data.message);
                }
            } catch (error) {
                console.error('Errore durante la registrazione.', error);
            }
        };

        completeRegistration();
    }, [uidb64, token, navigate]);

    return (
        <div>
            <h2>Completing Registration...</h2>
        </div>
    );
};

export default CompleteRegister;
