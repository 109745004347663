import React, { useState } from 'react';
import styled from 'styled-components';

const FilterSection = styled.div`
  margin-bottom: 20px;
`;

const FilterTitle = styled.h3`
  margin: 0 0 10px;
  font-size: 1.2rem;
  color: #800080;
`;

const FilterInput = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const FilterSelect = styled.select`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const FilterButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #800080;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    background-color: #9932CC;
  }
`;

const ResetButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #ccc;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #999;
  }
`;

const SideBarFilters = ({ onFilterChange, onResetFilters }) => {
    const [filters, setFilters] = useState({
        search: '',
        category: '',
        priceMin: '',
        priceMax: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onFilterChange(filters);
    };

    const handleReset = () => {
        const resetFilters = {
            search: '',
            category: '',
            priceMin: '',
            priceMax: ''
        };
        setFilters(resetFilters);
        onResetFilters(); // Chiama la funzione di reset
    };

    return (
        <form onSubmit={handleSubmit}>
            <FilterSection>
                <FilterTitle>Ricerca</FilterTitle>
                <FilterInput
                    type="text"
                    name="search"
                    value={filters.search}
                    onChange={handleInputChange}
                    placeholder="Cerca..."
                />
            </FilterSection>
            <FilterSection>
                <FilterTitle>Categoria</FilterTitle>
                <FilterSelect
                    name="category"
                    value={filters.category}
                    onChange={handleInputChange}
                >
                    <option value="">Tutte</option>
                    <option value="electronics">Elettronica</option>
                    <option value="books">Libri</option>
                    <option value="clothing">Abbigliamento</option>
                    <option value="sports">Sport</option>
                    {/* Aggiungi altre categorie se necessario */}
                </FilterSelect>
            </FilterSection>
            <FilterSection>
                <FilterTitle>Prezzo Minimo</FilterTitle>
                <FilterInput
                    type="number"
                    name="priceMin"
                    value={filters.priceMin}
                    onChange={handleInputChange}
                    placeholder="€ Min"
                />
            </FilterSection>
            <FilterSection>
                <FilterTitle>Prezzo Massimo</FilterTitle>
                <FilterInput
                    type="number"
                    name="priceMax"
                    value={filters.priceMax}
                    onChange={handleInputChange}
                    placeholder="€ Max"
                />
            </FilterSection>
            <FilterButton type="submit">Filtra</FilterButton>
            <ResetButton type="button" onClick={handleReset}>Reset</ResetButton>
        </form>
    );
};

export default SideBarFilters;
