import React, { useState, useEffect } from 'react';
import axiosInstance from './api/axiosConfig';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div`
  padding: 20px;
`;

const AnnuncioItem = styled.div`
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
`;

const AdsFollow = () => {
  const [annunciSeguiti, setAnnunciSeguiti] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAnnunciSeguiti = async () => {
      try {
        const response = await axiosInstance.get('/annuncio/seguiti/');
        setAnnunciSeguiti(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Errore nel recupero degli annunci seguiti:', err);
        setError('Impossibile caricare gli annunci seguiti. Riprova più tardi.');
        setLoading(false);
      }
    };

    fetchAnnunciSeguiti();
  }, []);

  if (loading) return <div>Caricamento...</div>;
  if (error) return <div>{error}</div>;

  return (
    <Container>
      <h2>Annunci Seguiti</h2>
      {annunciSeguiti.length === 0 ? (
        <p>Non stai seguendo nessun annuncio al momento.</p>
      ) : (
        annunciSeguiti.map(annuncio => (
          <AnnuncioItem key={annuncio.id}>
            <h3>{annuncio.titolo}</h3>
            <p>Prezzo: €{annuncio.prezzo}</p>
            <Link to={`/annuncio/${annuncio.id}`}>Visualizza dettagli</Link>
          </AnnuncioItem>
        ))
      )}
    </Container>
  );
};

export default AdsFollow;