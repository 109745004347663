import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, Link } from 'react-router-dom';
import UserDropdown from './UserDropdown';

const NavbarContainer = styled.div`
  background-color: #008080;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
`;

const MainNavbarContainer = styled.div`
  background-color: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  border-bottom: 1px solid #eaeaea;
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: black;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;
`;

const NavLink = styled(Link)`
  color: black;
  text-decoration: none;
  font-size: 1rem;

  &:hover {
    text-decoration: underline;
  }
`;

const UserActions = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Button = styled.button`
  padding: 5px 10px;
  font-size: 1rem;
  color: white;
  background-color: #800080;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #9932CC;
  }
`;

const Navbar = ({ isUserLoggedIn, username, userId, avatar, handleLogout, onLoginClick }) => {
  const navigate = useNavigate();
  const [validUser, setValidUser] = useState(false);
  const [onboardingCompleted, setOnboardingCompleted] = useState(false);

  useEffect(() => {
    const checkUserValidity = async () => {
      if (isUserLoggedIn) {
        try {
          const token = localStorage.getItem('token');
          const response = await fetch('/api/check-user-validity', {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

          // Controlla se la risposta è in formato JSON
          const contentType = response.headers.get("content-type");
          if (!response.ok || !contentType || !contentType.includes("application/json")) {
            throw new Error('Risposta non valida dal server');
          }

          const data = await response.json();
          setValidUser(true);
          setOnboardingCompleted(data.onboardingCompleted);
          localStorage.setItem('uidb64', data.uidb64);
          if (!data.onboardingCompleted) {
            navigate(`/onboarding/${data.uidb64}/${token}/`);
          }
        } catch (error) {
          console.error('Errore durante la verifica dell\'utente:', error);
          setValidUser(false);
          handleLogout();
        }
      } else {
        setValidUser(false);
      }
    };

    checkUserValidity();
  }, [isUserLoggedIn, handleLogout, navigate]);

  const handleLoginClick = () => {
    console.log("Login button clicked");
    navigate('/api/login');
    onLoginClick();
  };

  return (
    <>
      <NavbarContainer>
        <NavLinks>
          {!isUserLoggedIn && <Button>Privati</Button>}
          {!isUserLoggedIn && <Button>Business</Button>}
        </NavLinks>
        <UserActions>
          {isUserLoggedIn && validUser && onboardingCompleted ? (
            <>
              <Button onClick={() => navigate('/inserisci_annuncio')}>Inserisci Annuncio</Button>
              <UserDropdown username={username} userId={userId} avatar={avatar} onLogout={handleLogout} />
            </>
          ) : (
            <Button onClick={handleLoginClick}>Accedi/Registrati</Button>
          )}
        </UserActions>
      </NavbarContainer>
      <MainNavbarContainer>
        <NavLink to="/"><Logo>Perstudenti.com</Logo></NavLink>

        <NavLinks>
          <NavLink to="/elenco_annunci">Acquista</NavLink>
          <NavLink to="/">Vendi</NavLink>
          <NavLink to="/">Chi Siamo</NavLink>
          <NavLink to="/">Recensioni</NavLink>
          <NavLink to="/">Come Funziona</NavLink>
        </NavLinks>
        {isUserLoggedIn && validUser && onboardingCompleted && <Button onClick={() => navigate('/inserisci_annuncio')}>Inserisci Annuncio</Button>}
      </MainNavbarContainer>
    </>
  );
};

export default Navbar;
