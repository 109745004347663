import React from 'react';
import styled from 'styled-components';

const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  margin-bottom: 20px;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
`;

const Button = styled.button`
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #333;
  }
`;

const Slide4 = ({ formData, handleChange, handleSubmit, prevStep }) => {
    return (
        <SlideContainer>
            <Title>Bolly è curioso e vuole sapere...</Title>
            <Select
                name="come_sentito"
                value={formData.come_sentito}
                onChange={handleChange('come_sentito')}
                required
            >
                <option value="">Seleziona...</option>
                <option value="affissioni">Affissioni</option>
                <option value="social">Social</option>
                <option value="google">Google</option>
                <option value="universita">Università</option>
                <option value="scuola">Scuola</option>
                <option value="altro">Altro</option>
            </Select>
            <Button onClick={prevStep}>Indietro</Button>
            <Button onClick={handleSubmit}>Fine</Button>
        </SlideContainer>
    );
};

export default Slide4;
