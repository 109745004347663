import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axiosInstance from './api/axiosConfig';  // Importa l'istanza Axios configurata

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 10px;

  &:focus {
    outline: none;
  }
`;

const DropdownMenu = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
  position: absolute;
  right: 0;
  top: 100%;
  background-color: white;
  color: black;
  min-width: 200px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const MenuItem = styled.button`
  background: none;
  border: none;
  color: black;
  padding: 12px 16px;
  text-align: left;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;

const LogoutButton = styled.button`
  background: none;
  border: none;
  color: black;
  padding: 12px 16px;
  text-align: left;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;

const UserDropdown = ({ username, userId, onLogout }) => {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleLogout = () => {
    const csrftoken = document.cookie.split('; ').find(row => row.startsWith('csrftoken')).split('=')[1];

    axiosInstance.post('/logout/', {}, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRFToken': csrftoken
      }
    })
      .then(response => {
        console.log('Logout successful:', response.data);
        onLogout();
        navigate('/');
      })
      .catch(error => {
        console.error('Logout error:', error);
      });
  };

  const handleNavigate = (path) => {
    const uidb64 = localStorage.getItem('uidb64');
    const token = localStorage.getItem('token');
    if (uidb64 && token) {
      navigate(`${path}?uidb64=${uidb64}&token=${token}`);
    } else {
      navigate(path);
    }
    setShowMenu(false);
  };

  return (
    <DropdownContainer>
      <DropdownButton onClick={toggleMenu}>
        {username}
        <img src="/path/to/notifications/icon" alt="Notifiche" />
      </DropdownButton>
      <DropdownMenu show={showMenu ? "true" : undefined}>
        <MenuItem onClick={() => handleNavigate(`/api/area_riservata/${userId}/`)}>Area Riservata</MenuItem>
        <MenuItem onClick={() => handleNavigate(`/api/area_riservata/${userId}/inserisci_annuncio`)}>Inserisci Annuncio</MenuItem>
        <MenuItem onClick={() => handleNavigate(`/api/area_riservata/${userId}/i_miei_annunci`)}>I miei annunci</MenuItem>
        <MenuItem onClick={() => handleNavigate(`/api/area_riservata/${userId}/annunci_seguiti`)}>Annunci Seguiti</MenuItem>
        <MenuItem onClick={() => handleNavigate(`/api/area_riservata/${userId}/direct_message`)}>Direct Message</MenuItem>
        <MenuItem onClick={() => handleNavigate(`/api/area_riservata/${userId}/bolly_rewards_program`)}>Bolly Rewards Program</MenuItem>
        <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
      </DropdownMenu>
    </DropdownContainer>
  );
};

export default UserDropdown;
