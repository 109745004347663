import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import axiosInstance from './api/axiosConfig';  // Usa l'istanza Axios configurata
import styled from 'styled-components';
import Card from './Card';  // Importa il componente Card

const ChatContainer = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ChatHeader = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const MessagesList = styled.ul`
  list-style-type: none;
  padding: 0;
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const MessageItem = styled.li`
  align-self: ${props => props.isSent ? 'flex-end' : 'flex-start'};
  background-color: ${props => props.isSent ? '#DCF8C6' : '#E8E8E8'};
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  max-width: 70%;
  width: fit-content;
`;

const MessageText = styled.p`
  margin: 0;
`;

const MessageTime = styled.span`
  font-size: 0.8em;
  color: #888;
  display: block;
  text-align: ${props => props.$sent ? 'right' : 'left'};
  margin-top: 5px;
`;

const DateDivider = styled.div`
  text-align: center;
  margin: 20px 0;
  color: #888;
  font-size: 0.9em;
`;

const SendMessageForm = styled.form`
  display: flex;
  margin-top: auto;
`;

const MessageInput = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
`;

const SendButton = styled.button`
  padding: 10px 20px;
  background-color: #800080;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #9932CC;
  }
`;

const ActionButton = styled.button`
  padding: 5px 10px;
  margin-left: 10px;
  background-color: #800080;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #720072;
  }
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #800080;

  &:hover {
    color: #720072;
  }
`;

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('it-IT', { year: 'numeric', month: 'long', day: 'numeric' });
};

const formatTime = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' });
};

const SentMessageItem = styled(MessageItem)`
  align-self: flex-end;
  background-color: #DCF8C6;
`;

const ReceivedMessageItem = styled(MessageItem)`
  align-self: flex-start;
  background-color: #E8E8E8;
`;

const SentMessage = ({ message }) => (
  <SentMessageItem>
    <MessageText>{message.testo}</MessageText>
    <MessageTime $sent>{formatTime(message.data_invio)}</MessageTime>
  </SentMessageItem>
);

const ReceivedMessage = ({ message }) => (
  <ReceivedMessageItem>
    <MessageText>{message.testo}</MessageText>
    <MessageTime>{formatTime(message.data_invio)}</MessageTime>
  </ReceivedMessageItem>
);

const AnnouncementCard = styled.div`
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
`;

const Chat = ({ conversationId, onClose, currentUserId, getOtherUsername, initialConversationData }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [otherUserName, setOtherUserName] = useState('');
    const [announcementData, setAnnouncementData] = useState(null);

    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const fetchMessages = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        console.log('Fetching messages for conversation:', conversationId);
        try {
          const response = await axiosInstance.get(`/chat/messages/conversazione/${conversationId}/`);
            console.log('Risposta completa:', response.data);
            
            if (response.data && Array.isArray(response.data) && response.data.length > 0) {
                setMessages(response.data);
                
                // Usa i dati iniziali della conversazione se disponibili
                const conversationData = initialConversationData || response.data[0];
                console.log('Conversation data:', conversationData);
                const otherUsername = getOtherUsername(conversationData);
                console.log('Risultato di getOtherUsername:', otherUsername);
                setOtherUserName(otherUsername);
            } else {
                console.log('Nessun messaggio ricevuto o dati non validi');
                setOtherUserName('Utente sconosciuto');
            }
        } catch (error) {
            console.error('Errore durante il recupero dei messaggi:', error.response ? error.response.data : error);
            setError('Impossibile caricare i messaggi. Riprova più tardi.');
        } finally {
            setIsLoading(false);
        }
    }, [conversationId, getOtherUsername, initialConversationData]);

    useEffect(() => {
        if (conversationId) {
            fetchMessages();
        }
    }, [conversationId, fetchMessages]);

    useEffect(scrollToBottom, [messages]);

    useEffect(() => {
        console.log('Current User ID:', currentUserId);
    }, [currentUserId]);

    useEffect(() => {
        if (messages.length > 0) {
            console.log('Primo messaggio:', messages[0]);
            console.log('Current User ID:', currentUserId);
            console.log('Other User Name:', otherUserName);
        }
    }, [messages, currentUserId, otherUserName]);

    useEffect(() => {
        console.log('Initial conversation data:', initialConversationData);
        if (initialConversationData && initialConversationData.annuncio) {
            console.log('Setting announcement data');
            setAnnouncementData({
                id: initialConversationData.annuncio,
                title: initialConversationData.titolo_annuncio,
                price: initialConversationData.prezzo_annuncio,
                image: initialConversationData.immagine_annuncio || 'URL_immagine_default'
            });
            console.log('Announcement data set:', {
                id: initialConversationData.annuncio,
                title: initialConversationData.titolo_annuncio,
                price: initialConversationData.prezzo_annuncio,
                image: initialConversationData.immagine_annuncio || 'URL_immagine_default'
            });
        } else {
            console.log('No announcement data in initialConversationData');
        }
    }, [initialConversationData]);

    const handleSendMessage = async (event) => {
        event.preventDefault();
        if (!newMessage.trim()) return;

        try {
            const response = await axiosInstance.post(`/chat/messages/conversazione/${conversationId}/`, {
                testo: newMessage,
            });
            setMessages(prevMessages => [...prevMessages, response.data]);
            setNewMessage('');
        } catch (error) {
            console.error('Errore durante l\'invio del messaggio:', error);
            setError('Impossibile inviare il messaggio. Riprova più tardi.');
        }
    };

    const handleMoveToTrash = async () => {
        try {
            await axiosInstance.post(`/chat/messages/conversazione/${conversationId}/trash/`);
            alert('Conversazione spostata nel cestino');
            onClose(); // Chiudi la chat dopo aver spostato nel cestino
        } catch (error) {
            console.error('Errore nello spostamento nel cestino:', error);
            setError('Impossibile spostare la conversazione nel cestino. Riprova più tardi.');
        }
    };

    const handleArchive = async () => {
        try {
            await axiosInstance.post(`/chat/messages/conversazione/${conversationId}/archive/`);
            alert('Conversazione archiviata');
            onClose(); // Chiudi la chat dopo l'archiviazione
        } catch (error) {
            console.error('Errore nell\'archiviazione:', error);
            setError('Impossibile archiviare la conversazione. Riprova più tardi.');
        }
    };

    const memoizedMessages = useMemo(() => {
        let lastDate = null;
        return messages.map((message, index) => {
            const messageDate = formatDate(message.data_invio);
            const showDateDivider = messageDate !== lastDate;
            lastDate = messageDate;
            const isSent = message.mittente.toString() === currentUserId.toString();
            return (
                <React.Fragment key={message.id}>
                    {showDateDivider && <DateDivider>{messageDate}</DateDivider>}
                    {isSent ? (
                        <SentMessage message={message} />
                    ) : (
                        <ReceivedMessage message={message} />
                    )}
                </React.Fragment>
            );
        });
    }, [messages, currentUserId]);

    if (isLoading) {
        return <div>Caricamento messaggi...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <ChatContainer>
            <CloseButton onClick={onClose}>&times;</CloseButton>
            <ChatHeader>Conversazione con {otherUserName}</ChatHeader>
            {announcementData && (
                <AnnouncementCard>
                    <Card
                        image={announcementData.image}
                        title={announcementData.title}
                        price={announcementData.price}
                        onClick={() => {/* Gestisci il click sull'annuncio */}}
                        horizontal={true}
                    />
                </AnnouncementCard>
            )}
            <ActionButtonsContainer>
                <ActionButton onClick={handleArchive}>Archivia</ActionButton>
                <ActionButton onClick={handleMoveToTrash}>Sposta nel cestino</ActionButton>
            </ActionButtonsContainer>
            <MessagesList>
                {memoizedMessages}
                <div ref={messagesEndRef} />
            </MessagesList>
            <SendMessageForm onSubmit={handleSendMessage}>
                <MessageInput
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Scrivi un messaggio..."
                />
                <SendButton type="submit">Invia</SendButton>
            </SendMessageForm>
        </ChatContainer>
    );
};

export default Chat;
