import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axiosInstance from './api/axiosConfig';  // Usa l'istanza Axios configurata

// Aggiungi questa funzione all'inizio del file, fuori dal componente
function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopupContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-width: 90%;
`;

const Title = styled.h2`
  color: #800080;
  margin-bottom: 20px;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  background-color: #800080;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    background-color: #720072;
  }
`;

const ChatPopup = ({ annuncioId, userId, onClose }) => {
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [conversationId, setConversationId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSending, setIsSending] = useState(false);

    useEffect(() => {
        const createOrFetchConversation = async () => {
            setIsLoading(true);
            try {
                const response = await axiosInstance.post(
                    `/chat/messages/inizia_conversazione/${annuncioId}/`,
                    {},  // Rimuoviamo il messaggio da qui
                    { timeout: 5000 }
                );
                setConversationId(response.data.conversazione_id);
            } catch (err) {
                console.error('Errore durante la creazione o il recupero della conversazione:', err);
                setError('Si è verificato un errore. Riprova più tardi.');
            } finally {
                setIsLoading(false);
            }
        };

        createOrFetchConversation();
    }, [annuncioId]);  // Rimuoviamo message dalle dipendenze

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const handleSendMessage = async () => {
        if (!message.trim() || !conversationId) return;

        setIsSending(true);
        try {
            const response = await axiosInstance.post(
                `/chat/messages/conversazione/${conversationId}/`,
                { 
                    testo: message,
                    annuncio_id: annuncioId  // Aggiungi l'ID dell'annuncio
                },
                {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'X-CSRFToken': getCookie('csrftoken'),
                    },
                    withCredentials: true,
                }
            );

            if (response.status === 201) {
                console.log('Messaggio inviato con successo:', response.data);
                setMessage('');
                setError('');
                // Qui potresti voler aggiornare lo stato globale o fare una chiamata a una funzione di callback
                // per informare il componente genitore che una nuova conversazione è stata creata
                setTimeout(() => onClose(), 1500);
            } else {
                setError('Errore nell\'invio del messaggio.');
            }
        } catch (error) {
            console.error('Errore durante l\'invio del messaggio:', error);
            setError('Si è verificato un errore. Riprova più tardi.');
        } finally {
            setIsSending(false);
        }
    };

    return (
        <Overlay>
            <PopupContainer>
                <Title>Contatta il venditore</Title>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                {isLoading ? (
                    <p>Caricamento in corso...</p>
                ) : (
                    <>
                        <TextArea
                            value={message}
                            onChange={handleMessageChange}
                            placeholder="Scrivi il tuo messaggio qui..."
                            disabled={isSending}
                        />
                        <div>
                            <Button 
                                onClick={handleSendMessage} 
                                disabled={!conversationId || !message.trim() || isSending}
                            >
                                {isSending ? 'Invio in corso...' : 'Invia'}
                            </Button>
                            <Button onClick={onClose} disabled={isSending}>Annulla</Button>
                        </div>
                    </>
                )}
            </PopupContainer>
        </Overlay>
    );
};

export default ChatPopup;
