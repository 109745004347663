import React, { useEffect, useState, useRef } from 'react';
import axiosInstance from './api/axiosConfig';  // Usa l'istanza Axios configurata
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Card from './Card';
import Chip from './Chip';

const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 64px 112px 64px 112px;
`;

const LatestAdsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 20px 0;

  @media (min-width: 768px) {
    overflow-x: hidden;
  }
`;

const Title = styled.h1`
  color: #800080;
  font-size: 4rem;
  margin-bottom: 64px;
  text-align: center;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: center;
  margin-bottom: 20px;
`;

const ScrollButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  img {
    width: 24px;
    height: 24px;
  }

  &.left {
    margin-right: 10px;
    margin-left: 56px;
  }

  &.right {
    margin-left: 14px;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

function LatestAds() {
  const [ads, setAds] = useState([]); // Assicurati che sia sempre un array
  const [filteredAds, setFilteredAds] = useState([]); // Assicurati che sia sempre un array
  const [selectedFilter, setSelectedFilter] = useState(''); // Modificato da 'I più seguiti' a ''
  const [leftArrow, setLeftArrow] = useState('');
  const [rightArrow, setRightArrow] = useState('');
  const adsContainerRef = useRef(null);
  const navigate = useNavigate();

  // Carica gli annunci al montaggio del componente
  useEffect(() => {
    fetchAds('/most_followed/');
    axiosInstance.get('/assets/list/')
      .then(response => {
        const leftArrowAsset = response.data.find(asset => asset.name === 'leftArrow');
        const rightArrowAsset = response.data.find(asset => asset.name === 'rightArrow');
        setLeftArrow(leftArrowAsset ? leftArrowAsset.file_url : '');
        setRightArrow(rightArrowAsset ? rightArrowAsset.file_url : '');
      })
      .catch(error => console.error('Error fetching assets:', error));
  }, []);

  // Gestione dei filtri
  useEffect(() => {
    if (ads.length === 0) return; // Assicurati di non eseguire alcuna azione se gli annunci non sono stati caricati

    switch (selectedFilter) {
      case 'All':
        fetchAds('/api/elenco_annunci/');
        break;
      case 'I più seguiti':
        fetchAds('/api/most_followed/');
        break;
      case 'I più visti':
        fetchAds('/api/most_viewed/');
        break;
      case 'I più cercati':
        fetchAds('/api/most_searched/');
        break;
      case 'Usato':
      case 'Come nuovo':
      case 'Nuovo':
        fetchAds(`/api/condition/${selectedFilter}/`);
        break;
      default:
        setFilteredAds(ads); // Se non c'è filtro selezionato, mostra tutti gli annunci
        break;
    }
  }, [selectedFilter, ads]);

  // Funzione per caricare gli annunci dall'API
  const fetchAds = (url) => {
    axiosInstance.get(url)
      .then(response => {
        setAds(response.data || []); // Imposta un array vuoto se la risposta non contiene dati
        setFilteredAds(response.data || []); // Imposta un array vuoto se la risposta non contiene dati
      })
      .catch(error => console.error('Error fetching ads:', error));
  };

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
  };

  const handleCardClick = (id) => {
    navigate(`/annuncio/${id}`);
  };

  const scrollLeft = () => {
    adsContainerRef.current.scrollLeft -= 200;
  };

  const scrollRight = () => {
    adsContainerRef.current.scrollLeft += 200;
  };

  return (
    <Container>
      <Title>Scopri gli ultimi annunci</Title>
      <FilterContainer>
        {['I più seguiti', 'I più visti', 'I più cercati', 'Usato', 'Come nuovo', 'Nuovo'].map(filter => (
          <Chip
            key={filter}
            label={filter}
            onClick={() => handleFilterClick(filter)}
            active={(selectedFilter === filter).toString()}
          />
        ))}
        <ScrollButton className="left" onClick={scrollLeft}>
          <img src={leftArrow} alt="Scroll left" />
        </ScrollButton>

        <ScrollButton className="right" onClick={scrollRight}>
          <img src={rightArrow} alt="Scroll right" />
        </ScrollButton>
      </FilterContainer>
      <LatestAdsContainer ref={adsContainerRef}>
        {Array.isArray(filteredAds) && filteredAds.length > 0 ? (
          filteredAds.map(ad => (
            <Card
              key={ad.id}
              image={ad.img_main_url}
              title={ad.titolo}
              price={ad.prezzo}
              onClick={() => handleCardClick(ad.id)}
            />
          ))
        ) : (
          <p>No ads available</p>
        )}
      </LatestAdsContainer>
    </Container>
  );
}

export default LatestAds;
