import React, { useState } from 'react';
import styled from 'styled-components';
import axiosInstance from './api/axiosConfig';  // Usa l'istanza Axios configurata

const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
`;

const RegisterForm = styled.form`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  text-align: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
`;

const FileInput = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #333;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin: 10px 0;
`;

const SuccessMessage = styled.p`
  color: green;
  margin: 10px 0;
`;

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

function Register() {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password1: '',
        password2: '',
        termsAccepted: false,
        avatar: null,
    });
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            avatar: e.target.files[0],
        });
    };

    const handleCheckboxChange = (e) => {
        setFormData({
            ...formData,
            termsAccepted: e.target.checked,
        });
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        const csrftoken = getCookie('csrftoken');

        const data = new FormData();
        data.append('username', formData.username);
        data.append('email', formData.email);
        data.append('password1', formData.password1);
        data.append('password2', formData.password2);
        data.append('termsAccepted', formData.termsAccepted);
        if (formData.avatar) {
            data.append('avatar', formData.avatar);
        }

        try {
            const response = await axiosInstance.post('/api/registrazione/', data, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRFToken': csrftoken,
                },
                withCredentials: true,
            });

            if (response.data.status === 'success') {
                setSuccessMessage(response.data.message);
                setError('');
            } else {
                if (typeof response.data.message === 'object') {
                    setError(JSON.stringify(response.data.message));
                } else {
                    setError(response.data.message);
                }
                setSuccessMessage('');
            }
        } catch (error) {
            setError('Errore durante la registrazione. Riprova più tardi.');
            console.error('Register error', error);
        }
    };

    return (
        <RegisterContainer>
            <RegisterForm onSubmit={handleRegister}>
                <Title>Registrazione Utente</Title>
                {error && <ErrorMessage>{error}</ErrorMessage>}
                {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
                <Input
                    type="text"
                    name="username"
                    placeholder="Username"
                    value={formData.username}
                    onChange={handleInputChange}
                    required
                />
                <Input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                />
                <Input
                    type="password"
                    name="password1"
                    placeholder="Password"
                    value={formData.password1}
                    onChange={handleInputChange}
                    required
                />
                <Input
                    type="password"
                    name="password2"
                    placeholder="Conferma Password"
                    value={formData.password2}
                    onChange={handleInputChange}
                    required
                />
                <FileInput
                    type="file"
                    name="avatar"
                    onChange={handleFileChange}
                />
                <CheckboxContainer>
                    <Checkbox
                        type="checkbox"
                        name="termsAccepted"
                        checked={formData.termsAccepted}
                        onChange={handleCheckboxChange}
                        required
                    />
                    <label htmlFor="termsAccepted">Accetto i termini e le condizioni</label>
                </CheckboxContainer>
                <Button type="submit">Registrati</Button>
            </RegisterForm>
        </RegisterContainer>
    );
}

export default Register;
