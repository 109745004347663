import React, { useEffect, useState } from 'react';
import axiosInstance from './api/axiosConfig';  // Usa l'istanza Axios configurata
import styled from 'styled-components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SideBarFilters from './SideBarFilters';

const Container = styled.div`
  display: flex;
`;

const FiltersContainer = styled.div`
  width: 250px;
  background-color: #f8f8f8;
  padding: 20px;
  box-sizing: border-box;
`;

const AnnunciContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  width: 300px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const CardImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const CardContent = styled.div`
  padding: 15px;
`;

const CardTitle = styled.h3`
  margin: 0 0 10px;
  font-size: 1.2rem;
  color: #800080;
`;

const CardPrice = styled.p`
  margin: 0;
  font-size: 1rem;
  color: #69A6F7;
  font-weight: bold;
`;

const CardDescription = styled.p`
  margin: 10px 0 0;
  color: #666;
  font-size: 0.9rem;
`;

const ElencoAnnunci = ({ searchQuery }) => {
  const [annunci, setAnnunci] = useState([]);
  const [filters, setFilters] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAnnunci = async () => {
      try {
        const params = new URLSearchParams(location.search);
        const category = params.get('category');
        const query = params.get('q') || searchQuery;
        const response = await axiosInstance.get('/annuncio/elenco_annunci/', { params: { ...filters, category, q: query } });
        setAnnunci(response.data);
      } catch (error) {
        console.error('Error fetching annunci:', error);
      }
    };

    fetchAnnunci();
  }, [filters, location, searchQuery]);

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    const searchParams = new URLSearchParams(newFilters);
    navigate(`/elenco_annunci?${searchParams.toString()}`);
  };

  const handleResetFilters = () => {
    setFilters({});
    navigate('/elenco_annunci');
  };

  return (
    <Container>
      <FiltersContainer>
        <SideBarFilters onFilterChange={handleFilterChange} onResetFilters={handleResetFilters} />
      </FiltersContainer>
      <AnnunciContainer>
        {annunci.map(annuncio => (
          <Card key={annuncio.id}>
            <Link to={`/annuncio/${annuncio.id}`}>
              <CardImage src={annuncio.img_main || '/default-image.jpg'} alt={annuncio.titolo} />
              <CardContent>
                <CardTitle>{annuncio.titolo}</CardTitle>
                <CardPrice>€{annuncio.prezzo}</CardPrice>
                <CardDescription>{annuncio.descrizione}</CardDescription>
              </CardContent>
            </Link>
          </Card>
        ))}
      </AnnunciContainer>
    </Container>
  );
};

export default ElencoAnnunci;
