// App.jsx
import React, { useState, useEffect, createContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Navbar from './Navbar';
import Header from './Header';
import LatestAds from './LatestAds';
import Categories from './Categories';
import Promotions from './Promotions';
import Reviews from './Reviews';
import Blog from './Blog';
import Login from './Login';
import AreaRiservata from './AreaRiservata';
import Annuncio from './Annuncio';
import ElencoAnnunci from './ElencoAnnunci';
import Register from './Register';
import ConfirmUser from './ConfirmUser';
import CompleteRegister from './CompleteRegister';
import Onboarding from './Onboarding';
import axiosInstance from './api/axiosConfig';
export const AuthContext = createContext();

function App() {
  const [showLogin, setShowLogin] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);
  const [username, setUsername] = useState('');
  const [avatar, setAvatar] = useState('');
  const [hasCompletedOnboarding, setHasCompletedOnboarding] = useState(false);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    const storedUsername = localStorage.getItem('username');
    const storedAvatar = localStorage.getItem('avatar');
    const storedHasCompletedOnboarding = localStorage.getItem('hasCompletedOnboarding') === 'true';
    const storedToken = localStorage.getItem('token');
    if (storedUserId && storedUsername && storedToken) {
      console.log('Restoring session from local storage');
      setIsUserLoggedIn(true);
      setUserId(storedUserId);
      setUsername(storedUsername);
      setAvatar(storedAvatar);
      setHasCompletedOnboarding(storedHasCompletedOnboarding);
      setToken(storedToken);
    } else {
      console.log('No session found in local storage');
    }
  }, []);


  const handleLoginClick = () => {
    setShowLogin(true);
  };

  const handleCloseLogin = () => {
    setShowLogin(false);
  };

  const handleLoginSuccess = (userId, username, avatar, token, onboardingCompleted, uidb64) => {
    console.log('Login successful:', { userId, username, avatar, token, onboardingCompleted });
    setIsUserLoggedIn(true);
    setUserId(userId);
    setUsername(username);
    setAvatar(avatar);
    setHasCompletedOnboarding(onboardingCompleted);
    setToken(token);
    localStorage.setItem('userId', userId);
    localStorage.setItem('username', username);
    localStorage.setItem('avatar', avatar);
    localStorage.setItem('hasCompletedOnboarding', onboardingCompleted ? 'true' : 'false');
    localStorage.setItem('token', token);
    localStorage.setItem('uidb64', uidb64);  // Aggiungi questa linea
    setShowLogin(false);
  };

  const handleLogout = async () => {
    try {
      await axiosInstance.post('/logout/', {}, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
        withCredentials: true
      });
      setIsUserLoggedIn(false);
      setUserId(null);
      setUsername('');
      setAvatar('');
      setHasCompletedOnboarding(false);
      setToken(null);
      localStorage.removeItem('userId');
      localStorage.removeItem('username');
      localStorage.removeItem('avatar');
      localStorage.removeItem('hasCompletedOnboarding');
      localStorage.removeItem('token');
    } catch (error) {
      console.error('Logout error', error);
    }
  };

  const handleUserUpdate = (userId, username, avatar) => {
    setUserId(userId);
    setUsername(username);
    setAvatar(avatar);
    localStorage.setItem('userId', userId);
    localStorage.setItem('username', username);
    localStorage.setItem('avatar', avatar);
  };

  const handleCompleteOnboarding = () => {
    setHasCompletedOnboarding(true);
    localStorage.setItem('hasCompletedOnboarding', 'true');

    // Recupera gli altri dati dell'utente dallo stato e salva nel localStorage
    localStorage.setItem('userId', userId);
    localStorage.setItem('username', username);
    localStorage.setItem('avatar', avatar);
    localStorage.setItem('token', token);

    console.log('Onboarding completato, reindirizzando alla home page');
    window.location.href = '/';
  };

  return (
    <AuthContext.Provider value={{ isUserLoggedIn, userId, username, avatar, handleLogout, handleUserUpdate, handleLoginSuccess }}>
      <Router>
        <div className="App">
          <Navbar
            isUserLoggedIn={isUserLoggedIn}
            username={username}
            userId={userId}
            avatar={avatar}
            handleLogout={handleLogout}
            onLoginClick={handleLoginClick}
          />
          {showLogin && <Login onClose={handleCloseLogin} onLoginSuccess={handleLoginSuccess} />}
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Header />
                  <LatestAds />
                  <Categories />
                  <Promotions />
                  <Reviews />
                  <Blog />
                </>
              }
            />
            <Route path="/api/login" element={<Login onClose={handleCloseLogin} onLoginSuccess={handleLoginSuccess} />} />
            <Route path="/api/area_riservata/:userId/*" element={
              isUserLoggedIn && !hasCompletedOnboarding ? <Navigate to={`/onboarding/${userId}/${token}`} /> : <AreaRiservata />
            } />
            <Route path="/annuncio/:id" element={<Annuncio />} />
            <Route path="/elenco_annunci" element={<ElencoAnnunci />} />
            <Route path="/registrazione" element={<Register />} />
            <Route path="/confirm_user/:uidb64/:token" element={<ConfirmUser />} />
            <Route path="/complete_registration/:uidb64/:token" element={<CompleteRegister />} />
            <Route path="/onboarding/:uidb64/:token" element={<Onboarding onComplete={handleCompleteOnboarding} />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
