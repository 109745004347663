import React from 'react';
import styled from 'styled-components';

const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

const Button = styled.button`
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #333;
  }
`;

const Slide1 = ({ formData, handleChange, nextStep }) => {
    return (
        <SlideContainer>
            <Title>Dacci qualche info in più su di te</Title>
            <Input
                type="text"
                name="nome"
                placeholder="Nome"
                value={formData.nome}
                onChange={handleChange('nome')}
                required
            />
            <Input
                type="text"
                name="cognome"
                placeholder="Cognome"
                value={formData.cognome}
                onChange={handleChange('cognome')}
                required
            />
            <CheckboxContainer>
                <input
                    type="checkbox"
                    name="studente"
                    checked={formData.studente}
                    onChange={handleChange('studente')}
                />
                <label>Studente?</label>
            </CheckboxContainer>
            <Button onClick={nextStep}>Avanti</Button>
        </SlideContainer>
    );
};

export default Slide1;
