import React from 'react';
import styled from 'styled-components';

const BlogContainer = styled.section`
  padding: 20px;
  background-color: #fff;
  text-align: center;
`;

const SectionTitle = styled.h2`
  color: #333;
  margin-bottom: 20px;
`;

const BlogGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const BlogCard = styled.div`
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  width: 250px;
  text-align: center;
`;

const Blog = () => (
    <BlogContainer>
        <SectionTitle>Le ultime dal nostro blog</SectionTitle>
        <BlogGrid>
            {/* Replace with dynamic blog post components */}
            <BlogCard>Post 1</BlogCard>
            <BlogCard>Post 2</BlogCard>
            <BlogCard>Post 3</BlogCard>
            <BlogCard>Post 4</BlogCard>
        </BlogGrid>
    </BlogContainer>
);

export default Blog;
