import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axiosInstance from './api/axiosConfig';  // Use the configured Axios instance
import Slide1 from './Slide1';
import Slide2 from './Slide2';
import Slide3 from './Slide3';
import Slide4 from './Slide4';
import { useNavigate, useParams } from 'react-router-dom';

const OnboardingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const OnboardingContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
`;

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

const Onboarding = ({ onComplete }) => {
    const [step, setStep] = useState(1);
    const { uidb64, token } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        nome: '',
        cognome: '',
        studente: false,
        via: '',
        numero_civico: '',
        cap: '',
        citta: '',
        provincia: '',
        motivo_iscrizione: '',
        come_sentito: '',
        avatar: null,
    });

    useEffect(() => {
        console.log('useEffect triggered with uidb64:', uidb64, 'and token:', token);
        if (!uidb64 || !token) {
            const uidb64Stored = localStorage.getItem('uidb64');
            const tokenStored = localStorage.getItem('token');
            if (uidb64Stored && tokenStored) {
                console.log('Navigating to stored UID and token');
                navigate(`/onboarding/${uidb64Stored}/${tokenStored}/`);
            } else {
                console.error('UID or token not provided');
                navigate('/');  // Redirect the user if parameters are missing
            }
        } else {
            console.log('Setting UID and token in localStorage');
            localStorage.setItem('uidb64', uidb64);
            localStorage.setItem('token', token);

            // Define the function inside useEffect
            const checkOnboardingStatus = async () => {
                try {
                    const response = await axiosInstance.get('/api/check-user-validity', {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });
                    if (response.data.onboardingCompleted) {
                        console.log('Onboarding already completed');
                        onComplete();
                        navigate('/');
                    }
                } catch (error) {
                    console.error('Error checking onboarding status:', error);
                }
            };

            // Call the function
            checkOnboardingStatus();
        }
    }, [uidb64, token, navigate, onComplete]);

    const nextStep = () => {
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    const handleChange = (input) => (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async () => {
        const csrftoken = getCookie('csrftoken');
        const data = new FormData();
        for (const key in formData) {
            data.append(key, formData[key]);
        }

        const storedUidb64 = localStorage.getItem('uidb64');
        const storedToken = localStorage.getItem('token');

        if (!storedUidb64 || !storedToken) {
            console.error('UID or token not found in localStorage');
            return;
        }

        try {
            console.log('Sending POST request to /api/onboarding with UID:', storedUidb64, 'and token:', storedToken);
            const response = await axiosInstance.post(`/onboarding/${storedUidb64}/${storedToken}/`, data, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRFToken': csrftoken,
                },
                withCredentials: true,
            });

            if (response.data.status === 'success') {
                console.log('Onboarding successful');
                localStorage.setItem('hasCompletedOnboarding', 'true'); // Added this line
                onComplete();
            } else {
                console.error('Error during onboarding:', response.data.message);
            }
        } catch (error) {
            console.error('Errore durante la registrazione.', error.response ? error.response.data : error);
            // Log the full error object for debugging
            console.error('Full error object:', error);
        }
    };

    switch (step) {
        case 1:
            return (
                <OnboardingContainer>
                    <OnboardingContent>
                        <Slide1 formData={formData} handleChange={handleChange} nextStep={nextStep} />
                    </OnboardingContent>
                </OnboardingContainer>
            );
        case 2:
            return (
                <OnboardingContainer>
                    <OnboardingContent>
                        <Slide2 formData={formData} handleChange={handleChange} nextStep={nextStep} prevStep={prevStep} />
                    </OnboardingContent>
                </OnboardingContainer>
            );
        case 3:
            return (
                <OnboardingContainer>
                    <OnboardingContent>
                        <Slide3 formData={formData} handleChange={handleChange} nextStep={nextStep} prevStep={prevStep} />
                    </OnboardingContent>
                </OnboardingContainer>
            );
        case 4:
            return (
                <OnboardingContainer>
                    <OnboardingContent>
                        <Slide4 formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} prevStep={prevStep} />
                    </OnboardingContent>
                </OnboardingContainer>
            );
        default:
            return null;
    }
};

export default Onboarding;
