import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axiosInstance from './api/axiosConfig';  // Usa l'istanza Axios configurata
import { useNavigate } from 'react-router-dom';

const CategoriesContainer = styled.section`
  padding: 20px;
  background-color: #fff;
`;

const SectionTitle = styled.h2`
  color: #333;
  text-align: center;
`;

const CategoriesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Tre colonne */
  gap: 20px; /* Spaziatura tra gli elementi */
  justify-content: center;
  justify-items: center; /* Centra i pulsanti all'interno delle celle */
`;

const CategoryButton = styled.button`
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 50px; /* Per ottenere i bordi arrotondati come nell'immagine */
  padding: 20px;
  margin: 10px;
  width: 204px; /* Larghezza fissa */
  height: 104px; /* Altezza fissa */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-shrink: 0; /* Evita che i pulsanti si restringano */
  
  &:hover {
    background-color: #f0f0f0;
  }
`;

const CategoryIcon = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
`;

const CategoryName = styled.span`
  font-size: 1.2rem;
  color: #333;
  font-weight: bold;
`;

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance.get('/approved_categories/')
      .then(response => {
        console.log(response.data); // Aggiungi questo log per vedere i dati ricevuti
        setCategories(response.data);
      })
      .catch(error => console.error('Error fetching categories:', error));
  }, []);

  const handleCategoryClick = (categoryName) => {
    navigate(`/elenco_annunci?category=${categoryName}`);
  };

  return (
    <CategoriesContainer>
      <SectionTitle>Cosa puoi trovare da noi oggi</SectionTitle>
      <CategoriesGrid>
        {categories.map(category => (
          <CategoryButton key={category.name} onClick={() => handleCategoryClick(category.name)}>
            <CategoryIcon src={category.icon_url} alt={category.name} />
            <CategoryName>{category.name}</CategoryName>
          </CategoryButton>
        ))}
      </CategoriesGrid>
    </CategoriesContainer>
  );
};

export default Categories;
